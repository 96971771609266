import { Box, Grid, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@mui/styles';
import {
  arrayOf, bool, func, number, oneOf, oneOfType, shape, string
} from 'prop-types';
import React, { useEffect, useState } from 'react';
import FlagIcon from './FlagIcon';
import { connect } from 'react-redux';
import { getTransferRates } from '../../redux/rates/actions';
import { fetchCountriesFromRates } from '../../redux/system/actions';
import Select from './Select';
import { snackbar } from './Notifier';

const canadaObject = { iso2: 'CA', calling_code: 1, country_to_id: 40 };

const useStyles = makeStyles(theme => ({
  root: {
    border: '1px solid #CACCD5',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '48px',
  },
  field: {
    width: '100%',
    height: '48px'
  },
  code: {
    paddingLeft: 2,
    color: '#37517A',
    display: 'inline-block',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1, 0, 2)
  },
  currency: {
    paddingLeft: theme.spacing(1),
    fontSize: '16px'
  },
  menu: {
    padding: 0
  },
  input: {
    "& fieldset": {
      border: '1px solid #CACCD5',
      height: '53px',
      borderRadius: '8px'
    },
  },
  accountRoot: {
    paddingTop: '7px',
    paddingLeft: '8px',
    marginTop: '6.5px',
    border: '1px solid #CACCD5',
    borderRadius: '5px',
    width: '65%',
    '@media (max-width: 959px)': {
      width: '50%',
    },
    '@media (max-width: 500px)': {
      maxWidth: '78px',
      width: '85px',
      paddingLeft: '6px'
    },
    '@media (max-width: 410px)': {
      maxWidth: '100px',
      width: '100px',
      paddingLeft: '6px'
    },
    '@media (max-width: 360px)': {
      width: '68px',
      paddingLeft: '5px'
    },
  },
  countryIconProps: {
    right: '7px !important',
    color: '#1160F7'
}
}));

const CountryCodeSelect = ({
  value,
  countries,
  fetchCountries,
  readOnly,
  limitTo,
  name,
  useField,
  accountSettings,
  callingCode,
  ...props
}) => {
  let initialValue = value;
  if (initialValue && countries) {
    ([initialValue] = countries.filter(({ iso2, country_to_id }) => (value === iso2 || value === country_to_id)));
    if (initialValue) initialValue = initialValue[useField];
  }
  if (!initialValue) initialValue = canadaObject[useField];
  const [values, setValues] = useState(initialValue);
  const classes = useStyles();
  const fieldProps = props && props.field ? props.field : {};

  const handleChange = ({ target }) => {
    if (target.value) setValues(target.value);
  };

  const fetchData = async () => {
    if (readOnly) return;

    const { data, errorMessage, success } = await getTransferRates();
    if (success) {
      const { TransferRatesOnline } = data;
      fetchCountries(TransferRatesOnline);
    } else snackbar.error(errorMessage);
  };

  useEffect(() => {
    if ((!countries || countries.length === 0)) fetchData();
  }, []);
  let countriesContent = null;
  if (countries) {
    if (limitTo) {
      countries = countries.filter((element) => (limitTo.includes(element.iso2) || limitTo.includes(element.country_to_id)));
      if (limitTo.includes('CA')) countries = [canadaObject, ...countries];
    } else {
      if (countries.some(({ iso2 }) => iso2 === 'CA') === false) countries = [canadaObject, ...countries];
    }
   

    countriesContent = (
      countries.map((country) => (
        <MenuItem key={country.iso2} value={country[useField]}>
          <Box display='flex' alignItems='center'>
            <FlagIcon code={country.iso2} />
            <Typography className={classes.currency}>
              {`+ ${country.calling_code}`}
            </Typography>
          </Box>
        </MenuItem>
      ))
    );
  }

  return readOnly ?
    (<MenuItem key={value || 'CA'} value={value || 'CA'} disabled={readOnly} className={classes.menu}>
      <Grid container className={classes.root}>
        <Grid item className={classes.item}>
          <FlagIcon code={value || 'CA'} />
        </Grid>
        <Grid item xs className={classes.code}>
          <Typography style={{ fontSize: '16px' }}>
            {`+ ${callingCode || 1}`}
          </Typography>
        </Grid>
      </Grid>
    </MenuItem>) : accountSettings ?
      (<MenuItem key={'CA'} value={'CA'} className={classes.menu}>
        <Grid container className={classes.accountRoot}>
          <Grid item className={classes.item}>
            <FlagIcon code={'CA'} />
          </Grid>
          <Grid item xs className={classes.code}>
            <Typography>
              {`+ ${1}`}
            </Typography>
          </Grid>
        </Grid>
      </MenuItem>)
      :
      (<Select
        value={values}
        variant='outlined'
        onChange={handleChange}
        className={[classes.field, classes.input]}
        select
        iconProps={{ classes: { icon: classes.countryIconProps } }}
        readOnly
        name={name}
        {...props}
        {...fieldProps}
      >
        {countriesContent}
      </Select>
      );
};

CountryCodeSelect.propTypes = {
  value: oneOfType([string, number]),
  countries: arrayOf(shape({
    id: number,
    country_to: string,
    country_to_id: number,
    currency_name: string,
    iso3: string,
    iso2: string,
    calling_code: number,
  })),
  fetchCountries: func.isRequired,
  limitTo: arrayOf(
    oneOfType([string, number]),
  ),
  name: string,
  readOnly: bool,
  useField: oneOf(['iso2', 'country_to_id']),
};

CountryCodeSelect.defaultProps = {
  value: 40,
  countries: null,
  limitTo: null,
  name: 'phone_country_code',
  readOnly: false,
  useField: 'iso2',
};

const mapStateToProps = ({ system }) => ({
  countries: system.countries,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCountries: () => dispatch(fetchCountriesFromRates()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CountryCodeSelect);
