import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from '../../settings/i18n';
import Logo from './Logo';

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
        background: 'white',
        borderBottom: '1px solid #CACCD5',
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(3),
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3, 1),
        }
    },
    logo: {
        height: '34px',
        cursor: 'pointer',
        [theme.breakpoints.up('sm')]: {
          display: 'block'
        },
        [theme.breakpoints.down('sm')]: {
          display: 'none'
        }
      },
    iconButton: {
        marginLeft: 'auto',
        padding: 0
    }
}));

const Bar = ({ onClose }) => {
    const classes = useStyles();

    return (
        <AppBar className={classes.appBar} elevation={0}>
            <Toolbar>
                <Link href="/dashboard"> 
                    <Logo className={classes.logo} />
                </Link>
                {onClose &&
                    <IconButton className={classes.iconButton} onClick={onClose}>
                        <CloseIcon color='primary' />
                    </IconButton>
                }
            </Toolbar>
        </AppBar>
    )
};

export default Bar;
