import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { Autocomplete } from '@mui/lab';
import { FormControl, Paper, TextField, FormHelperText } from '@mui/material';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
//import MUIPlacesAutocomplete from 'mui-places-autocomplete';
import React, { useEffect, useState } from 'react';
//import Script from 'react-load-script';
import Script from 'next/script';

const useStyles = makeStyles(theme => ({
  label: {
    color: '#626680',
    fontSize: '12px',
    lineHeight: '18px',
    paddingBottom: 5
  },
  inputRoot: {
    borderRadius: '8px',
    height: '48px'
  },
  paper: {
    border: '1px solid #CACCD5',
    borderRadius: 5,
    marginTop: '12px'
  },
  option: {
    padding: theme.spacing(1, 2),
    fontSize: '16px',
    lineHeight: '24px',
    color: '#31517A',
    "&:hover": {
      backgroundColor: '#F1F6FF !important'
    }
  },
}));

const PlacesAutoComplete = ({
  onSelect,
  limitTo,
  field,
  form,
  disabled,
  addressFieldName,
  cityFieldName,
  provinceFieldName,
  postalFieldName,
  countryFieldName,
  label,
  error
}) => {
  const [typed, setTyped] = useState('');
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState(null);
  const classes = useStyles();

  if (form) {
    const { touched, errors, submitCount } = form;
    if (submitCount > 0) error = errors[field.name] || '';
    else error = touched[field.name] && errors[field.name] ? errors[field.name] : ''
  }

  const getFromAddress = (type, address, field = 'long_name') => {
    if (!address) {
      return '';
    }

    try {
      const detail = address.address_components.filter(component => component.types.includes(type)).map(c => c[field])[0];
      return (detail || '').toString().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    } catch (error) {
      return '';
    }
  }

  const loadSuggestions = () => {
    const params = {
      input: typed
    };

    if (limitTo) params.componentRestrictions = limitTo;

    if (form && field) form.setFieldValue(field.name, typed);

    const autocomplete = new window.google.maps.places.AutocompleteService();
    autocomplete.getPlacePredictions(params, (predictions) => {
      if (predictions && predictions.length) {
        setOptions(predictions.map(item => {
          return {
            value: item.structured_formatting?.main_text,
            label: item.description,
            id: item.place_id
          }
        }))
      }
    })
  }

  const handleNotPicking = () => {
    if (!selected && typed) {
      const append = {
        value: typed,
        label: typed
      };
      setOptions([append])
      setSelected(append);

      if (form & field) form.setFieldValue(field.name, typed);
    }
  }

  const getPlaceDetails = (placeId) => {
    let promise = new Promise(resolve => {
      const params = {
        placeId,
        fields: ['address_component']
      }

      const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));

      placesService.getDetails(params, (place) => {
        if (!place) return
        const province = getFromAddress('administrative_area_level_1', place, limitTo ? 'short_name' : 'long_name');
        const city = getFromAddress('locality', place);
        const postalCode = getFromAddress('postal_code', place);
        const address = getFromAddress('route', place);
        const streetNumber = getFromAddress('street_number', place);
        const country = getFromAddress('country', place);

        if (form) {
          addressFieldName && form.setFieldValue(addressFieldName, streetNumber
            ? `${streetNumber} ${address}` : address);
          cityFieldName && form.setFieldValue(cityFieldName, city);
          provinceFieldName && form.setFieldValue(provinceFieldName, province);
          postalFieldName && form.setFieldValue(postalFieldName, postalCode);
          countryFieldName && form.setFieldValue(countryFieldName, country);
        }

        if (onSelect) onSelect({
          province,
          city,
          postalCode,
          address,
          streetNumber,
          country
        });

        resolve({
          province,
          city,
          postalCode,
          address,
          streetNumber,
          country
        });
      });
    });

    return promise;
  }

  useEffect(() => {
    if (field && field.value) {
      const append = {
        value: field.value,
        label: field.value
      };
      setOptions([append])
      setSelected(append);
    }
  }, [])

  useEffect(() => {
    if (typed && typed !== '' && typed.length > 3) { //results will load after 3+ characters
      setSelected(null);
      loadSuggestions();
    }
  }, [typed])

  const handleChange = async (e, selectedValue) => {
    let details = await getPlaceDetails(selectedValue?.id);
    if (details) {
      const newOptions = options;
      const append = {
        value: selectedValue.value,
        label: selectedValue.value,
        id: selectedValue.id
      };
      setOptions(newOptions.push(append))
      setSelected(append);
      setOptions([]);
      if (field) form.setFieldValue(field.name, selectedValue.value);
    }
  }

  const CustomPaper = (props) => <Paper elevation={0} {...props} classes={{ root: classes.paper }} />

  return (
    <>
      <Script
          src={`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_API_KEY}&libraries=places&language=en`}
      />
      <FormControl fullWidth error={Boolean(error)} >
        {label && <Typography className={classes.label}>
          {label}
        </Typography>}
        <Autocomplete
          style={{ width: "100%" }}
          disableListWrap
          classes={{
            option: classes.option,
            inputRoot: classes.inputRoot
          }}
          options={options}
          getOptionLabel={({ label }) => label}
          noOptionsText={'No options found.'}
          popupIcon={<KeyboardArrowDown />}
          closeIcon={null}
          PaperComponent={CustomPaper}
          value={selected}
          disabled={disabled}
          onChange={handleChange}
          filterOptions={(options) => options}
          onInputChange={(e, inputValue, reason) => {
            if (reason !== 'reset') {
              setTyped && setTyped(e.target.value);
            }
          }}
          renderInput={params => (
            <TextField
              {...params}
              className={classes.input}
              autoComplete='off'
              placeholder='Start typing to search...'
              variant="outlined"
              fullWidth
              error={Boolean(error)}
              onBlur={handleNotPicking}
            />
          )}
        />
        {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </>
  )
}

export default PlacesAutoComplete;

// // Loads a script asyncrounously on the page header
// function loadScript(src, position, id) {
//   if (!position) {
//     return;
//   }

//   const script = document.createElement('script');
//   script.setAttribute('async', '');
//   script.setAttribute('id', id);
//   script.src = src;
//   position.appendChild(script);
// }

// const useStyles = makeStyles(theme => ({
//   notchedOutline: {
//     "& fieldset": {
//       border: '1px solid #CACCD5',
//       borderRadius: 5,
//       height: '53px',
//     },
//   },
//   label: {
//     color: '#626680',
//     fontSize: '12px',
//     lineHeight: '18px',
//     paddingBottom: 5
//   },
//   icon: {
//     color: theme.palette.text.secondary,
//     marginRight: theme.spacing(2),
//   },
//   '#rootRef > div > div:last-child': {
//     zIndex: '2000 !important',
//     position: 'inherit !important',
//     transform: 'inherit !important',
//   },
//   root: {
//     "& > div > div:last-child": {
//       zIndex: '2000 !important',
//       position: 'inherit !important',
//       transform: 'inherit !important',
//     }
//   }
// }));

// const GoogleMaps = ({
//   onSelect,
//   limitTo,
//   field,
//   form,
//   disabled,
//   addressFieldName,
//   cityFieldName,
//   provinceFieldName,
//   postalFieldName,
//   countryFieldName,
//   ...props
// }) => {
//   const classes = useStyles();
//   const [isLoaded, setLoaded] = React.useState(false);
//   const { t } = useTranslation('account-details');
//   const { value } = field;

//   const onSuggestionSelected = async (suggestion) => {

//     let details = await requestDetails(suggestion.place_id);
//     if (form) {
//       addressFieldName && form.setFieldValue(addressFieldName, details.streetNumber
//         ? `${details.streetNumber} ${details.address}` : details.address);
//       cityFieldName && form.setFieldValue(cityFieldName, details.city);
//       provinceFieldName && form.setFieldValue(provinceFieldName, details.province);
//       postalFieldName && form.setFieldValue(postalFieldName, details.postalCode);
//       countryFieldName && form.setFieldValue(countryFieldName, details.country);
//     }
//     if (onSelect) onSelect(details);
//   }

//   const handleTextFieldChange = (e) => {
//     if (form) {
//       // Invalid character found
//       const onChangeValue = e.target.value
//       const matchResult = (onChangeValue || '').toString().match(alphaNumericCharactersAllowed);
//       if (!matchResult || !matchResult.length) form.setFieldValue(field.name, onChangeValue);
//     }
//   }


//   const requestDetails = (id) => {

//     let promise = new Promise(resolve => {
//       var request = {
//         placeId: id,
//         fields: ['address_component']
//       };

//       /* eslint-disable-next-line no-undef */
//       const service = new google.maps.places.PlacesService(document.createElement('div'));

//       service.getDetails(request, (place) => {

//         let province = getFromAddress('administrative_area_level_1', place, limitTo ? 'short_name' : 'long_name');
//         let city = getFromAddress('locality', place);
//         let postalCode = getFromAddress('postal_code', place);
//         let address = getFromAddress('route', place);
//         let streetNumber = getFromAddress('street_number', place);
//         let country = getFromAddress('country', place);

//         resolve({
//           country,
//           city,
//           province,
//           address,
//           postalCode,
//           streetNumber
//         })
//       });
//     })

//     return promise;
//   }

//   const getFromAddress = (type, address, field = 'long_name') => {
//     if (!address) {
//       return '';
//     }

//     try {
//       const detail = address.address_components.filter(component => component.types.includes(type)).map(c => c[field])[0];
//       return (detail || '').toString().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
//     } catch (error) {
//       return '';
//     }
//   }

//   function createAutocompleteRequest(inputValue) {
//     return {
//       input: inputValue,
//       types: ['address'],
//       componentRestrictions: limitTo ? limitTo : {}
//     }
//   }

//   return (
//     <div id="rootRef" className={classes.root}>

//       <Script
//         url={`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_API_KEY}&libraries=places&language=en`}
//         onLoad={() => setLoaded(true)}
//       />
//       {

//         isLoaded ?
//           (
//             <>
//               <Typography className={classes.label}>{t('street_address')}</Typography>
//               <MUIPlacesAutocomplete
//                 onSuggestionSelected={onSuggestionSelected}
//                 renderTarget={() => (<div id="dropdownRef"></div>)}
//                 textFieldProps={{
//                   variant: 'outlined',
//                   placeholder: t("start_typing_address"),
//                   style: { width: "100%" },
//                   classes: {
//                     root: classes.notchedOutline
//                   },
//                   value,
//                   onChange: handleTextFieldChange,
//                   label: '',
//                   disabled
//                 }}
//                 createAutocompleteRequest={createAutocompleteRequest}
//               />
//             </>
//           ) : (
//             t('not_loaded')
//           )
//       }

//     </div>
//   );
// }

// GoogleMaps.propTypes = {
//   addressFieldName: string,
//   cityFieldName: string,
//   provinceFieldName: string,
//   postalFieldName: string,
//   countryFieldName: string,
// };

// export default GoogleMaps;
