import Grid from '@mui/material/Grid';
import React from 'react';

const Row = props => (
  <Grid
    container
    spacing={props.spacing ? props.spacing : 4}
    style={props.style}
    {...props}
    className={props.className}
  >
    {props.children}
  </Grid>
);
const Column = props => (
  <Grid
    item
    xs={props.xs}
    sm={props.sm}
    lg={props.lg}
    md={props.md}
    style={props.style}
    className={props.className}
  >
    {props.children}
  </Grid>
);
const HalfColumn = props => (
  <Column
    xs={props.xs ? props.xs : 12}
    sm={props.sm ? props.sm : 12}
    md={props.md ? props.md : 6}
    lg={props.lg ? props.lg : 6}
    style={props.style}
    {...props}
  />
);

const FullColumn = props => (
  <Column
    xs={props.xs ? props.xs : 12}
    lg={props.lg ? props.lg : 12}
    md={props.md ? props.md : 12}
    sm={props.sm ? props.sm : 12}
    style={props.style}
    {...props}
  />
);

const QuarterColumn = props => (
  <Column
    xs={props.xs ? props.xs : 6}
    sm={props.sm ? props.sm : 6}
    md={props.md ? props.md : 3}
    lg={props.lg ? props.lg : 3}
    style={props.style}
    {...props}
  />
);

const HalfOrFullColumn = ({ isFullColumn, children, ...props }) => {
  if (isFullColumn) {
    return <FullColumn {...props}>{children}</FullColumn>;
  }
  return (<HalfColumn {...props}>{children}</HalfColumn>)
};

export { Row, Column, HalfColumn, FullColumn, QuarterColumn, HalfOrFullColumn };
