import React from 'react';
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'

const NumberFormatCustom = ({ inputRef, format, ...other }) => {
    return (
        <NumberFormat
            allowNegative={false}
            getInputRef={inputRef}
            format={format}
            {...other}
        />
    )
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default NumberFormatCustom