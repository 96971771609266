import React, { useEffect, useRef, useState } from 'react';
import { withStyles } from '@mui/styles';

const styles = theme => ({
  root: {
    display: 'grid',
    gridAutoFlow: 'column',
    columnGap: theme.spacing(1.5),
    gridTemplateColumns: 'auto auto auto'
  },
  input: {
    width: '100%',
    border: '1px solid #CACCD5',
    borderRadius: '5px',
    height: '60px',
    [theme.breakpoints.down('xs')]: {
      height: '50px',
    },
    textAlign: 'center',
    fontFamily: 'Heebo',
    fontSize: '18px',
    color: '#31517A',
    fontWeight: 500,
    "&:focus": {
      border: '2px solid #1160F7',
      outline: 'none'
    }
  }
});

export const CodeVerificationInput = ({ classes, length, onComplete }) => {
  const [code, setCode] = useState([...Array(length)].map(() => ""));
  const codeRef = useRef([]);

  useEffect(() => {
    if ('OTPCredential' in window) { 
      window.addEventListener('DOMContentLoaded', () => {
        const ac = new AbortController();
        navigator.credentials.get({
          otp: { transport:['sms'] },
          signal: ac.signal
        }).then(otp => {
          const { code:otpCode } = otp;
          if(otpCode) {
          const splitCode = `${otpCode}`?.split("")?.map(Number)
          setCode([...splitCode])
        }
        }).catch(err => {
          console.log(err)
        });
      })
    }
  }, [])

  const handleCharChange = (event, index) => {
    const value = event.target.value;
    if (/[^0-9]/.test(value)) return;
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);
    if (index !== length - 1 && index + 1 !== length) {
      codeRef.current[index + 1].focus();
    }
    onComplete(newCode.join(""));
  }

  const handleKeyChange = (event, index) => {
    if (event.keyCode === 8 && !code[index] && index !== 0) {
      const newCode = [...code];
      newCode[index - 1] = "";
      setCode(newCode);
      if (codeRef.current[index - 1]) codeRef?.current[index - 1]?.focus();
    }
  }

  const handlePaste = (event) => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData('text/plain');
    const newCode = [...pastedData];
    if(newCode && newCode.length <= 6 && newCode.every(val => parseInt(val) >= 0) ) {
    setCode(newCode);
    onComplete(newCode.join(""));
    if (length > 0) codeRef.current[length - 1]?.focus();
    } 
  }

  return (
    <div className={classes?.root}>
      {code.map((item, index) => (
        <div key={index}>
          <input
            className={classes?.input}
            type="text"
            inputMode="numeric"
            autoComplete="one-time-code"
            maxLength={1}
            value={item}
            style={{ width: '100%' }}
            onKeyUp={(e) => handleKeyChange(e, index)}
            ref={(ref) => codeRef.current.push(ref)}
            onChange={(e) => handleCharChange(e, index)}
            autoFocus={!code[0].length && index === 0}
            onPaste={handlePaste}
            onInput={(e) => handleCharChange(e, index)}
          />
        </div>
      ))}
    </div>
  )
}

export default withStyles(styles)(CodeVerificationInput);