/* eslint-disable react/react-in-jsx-scope */
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import { emailCharactersAllowed } from '../../shared/regex';
import classnames from 'classnames'
import NumberFormat from './NumberFormat';
import { OutlinedInput, Tooltip } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';


const styleSheet = () => ({
  label: {
    color: '#626680',
    fontSize: '12px',
    lineHeight: '18px',
    paddingBottom: 5
  },
  notchedOutline: {
    border: '1px solid #CACCD5',
    borderRadius: '8px'
  },
  root: {
    height: '56px',
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: 'yellow !important',
      },
    }
  },
  input: {
    height: '56px',
    padding: '0 12px',
    '&::placeholder': {
      color: '#9D9C9C',
      opacity: 1
    }
  },
  helperText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tooltipStyle: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: '23.8px',
  },
  tooltip: {
    backgroundColor: 'white',
    color: '#626680',
    maxWidth: '245px',
    border: '1px solid #dadde9',
  },
  arrow: {
    color: '#dadde9',
  },
  icon: {
    fontSize: 20
  }
})

const TextField = props => {
  let { classes, type, label, onChange, labelClass, format, error, InputProps, field, form, charactersValidationIsEnabled, validationRegex, fieldId, tooltip, helperText, hideErrorMessage, ...otherProps } = props;
  let onChangeDefault = onChange;
  let charactersValidationEnabled = charactersValidationIsEnabled ?? true;
  const is_ben_phone = field && field.name === 'ben_phone1'

  InputProps = {
    ...InputProps,
    classes: {
      root: classes.root,
      notchedOutline: classes.notchedOutline,
      input: classes.input
    }
  }

  const TooltipLabel = (tooltip) => (
    <>
      <Tooltip
        title={
          <React.Fragment>
            <Typography color="inherit" className={classes.tooltipStyle}>
              {tooltip}
            </Typography>
          </React.Fragment>
        }
        placement="right"
        disableFocusListener
        disableTouchListener
        classes={{
          tooltip: classes.tooltip,
          arrow: classes.arrow,
        }}
        arrow
      >
        <HelpOutlineIcon className={classes.icon} color="primary" />
      </Tooltip>
    </>
  );

  let extraProps = { ...InputProps };

  if (form) {
    const { touched, errors, submitCount } = form;
    if (submitCount > 0) error = errors[field.name] || '';
    else error = touched[field.name] && errors[field.name] ? errors[field.name] : '';
    onChangeDefault = field.onChange;
  }

  if (format) {
    charactersValidationEnabled = false;
    const currentInputProps = InputProps ? InputProps : {};
    const inputProps = InputProps && InputProps.inputProps ? InputProps.inputProps : {};
    extraProps = {
      ...currentInputProps,
      inputComponent: NumberFormat,
      inputProps: {
        ...inputProps,
        inputMode: type === 'number' ? 'numeric' : 'text',
        format
      }
    };
  }

  const onChangeHandler = e => {
    const fieldType = props.type;
    if (charactersValidationEnabled && fieldType !== 'password') {
      const value = e.target.value.toString()
      let matchResult
      if (fieldType === 'email') matchResult = value.match(emailCharactersAllowed)
      else if (validationRegex) matchResult = value.match(validationRegex)

      // Invalid character found
      if (matchResult && matchResult.length) return;
    }
    
    onChangeDefault && onChangeDefault(e);
  };

  return (
    <FormControl fullWidth error={error ? true : false}>
      {label && <Typography className={classnames(classes.label, labelClass)} variant="body1">
        {label}
      </Typography>}
      <OutlinedInput
        id={fieldId}
        type={type === 'password' ? type : 'text'}
        {...otherProps}
        {...field}
        {...extraProps}
        onChange={onChangeHandler}
        endAdornment={tooltip ? TooltipLabel(tooltip) : InputProps?.endAdornment ? InputProps.endAdornment : null}
        error={error ? true : false} />
      {error && !is_ben_phone && !hideErrorMessage && <FormHelperText className={classes.helperText}>{error}</FormHelperText>}
      {helperText && !error && <FormHelperText className={classes.helperText} filled>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default withStyles(styleSheet)(TextField)
