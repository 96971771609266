const Router = require('next/router');
const Link = require('next/link');
const { useTranslation, withTranslation } = require('next-i18next');

module.exports = {
  Router,
  Link,
  useTranslation,
  withTranslation,
  i18n: {}
}