import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';

const styles = () => ({
  root: {
    objectFit: 'cover',
    maxWidth: '180px'
  },
  rootBusiness: {
    objectFit: 'cover',
    maxWidth: '220px'
  },
  portal: {
    maxWidth: '100%'
  }
})

const Logo = ({ classes, className, accountType, businessLogo, ...props }) => {
  let otherProps = {...props};
  delete otherProps.dispatch;
  const [isIE, setIsIE] = useState(false)
  if (typeof window !== 'undefined' && window.document && window.document.documentMode) setIsIE(true)
  const defaultSVGLogo = accountType !== 'business' && !businessLogo ? 'remitbee_logo.svg' : 'remitbee_business_logo.svg';
  const defaultPNGLogo = accountType !== 'business' && !businessLogo ? 'remitbee_logo.png' : 'remitbee_business_logo.png';
  return (
      <img
        className={`${businessLogo ? classes.rootBusiness : classes.root} ${className || ''} ${accountType === 'business' ? classes.portal : null}`}
        {...otherProps}
        src={
          ({
            white: isIE ? `/remitbee_logo_white.png` : `/remitbee_logo_white.svg`,
            normal: isIE ? `/${defaultPNGLogo}` : `/${defaultSVGLogo}`
          })[props.variant]
        }
        srcSet={
          ({
            white: isIE ? `/remitbee_logo_white.png 222w, /img/landing/main_logo_white_small.png 124w` : `/remitbee_logo_white.svg 222w, /img/landing/main_logo_white_small.png 124w`,
            normal: isIE ? `/${defaultPNGLogo} 288w, /remitbee_logo_small.png 127w` : `/${defaultSVGLogo} 288w, /remitbee_logo_small.png 127w`
          })[props.variant]
        }
        sizes="50vw"
        alt="Remitbee Online Money Transfer Logo"
      />
    )
};

Logo.propTypes = {
  variant: PropTypes.oneOf(['white', 'normal'])
}

Logo.defaultProps = {
  variant: 'normal',
}

const LogoWithStyles = withStyles(styles)(Logo)

const mapStateToProps = () => ({
  accountType: 'personal'
});

export default connect(mapStateToProps, null)(LogoWithStyles);